import { renderHTML } from '../utils/functions';

const PostMetaCategory = ({ post, attributes }) => {
	const { categories } = post;
	const { isMetaCategory, metaCategoryIn, metaCategoryIcon } = attributes;

	return (isMetaCategory && 'content' === metaCategoryIn) ? <span>
		{metaCategoryIcon ?
			<img src={metaCategoryIcon} alt='Author' /> :
			<span className='dashicons dashicons-category' />}

		{renderHTML(categories.coma)}
	</span> : null;
};
export default PostMetaCategory;