import MapPosts from './Layout/MapPosts';

const DisplayPosts = ({ posts, attributes, clientId }) => {
	const { layout, columns, sliderIsPage, sliderIsPrevNext } = attributes;
	const { desktop = 3, tablet = 2, mobile = 1 } = columns;

	const PostsLoop = () => <MapPosts posts={posts} attributes={attributes} clientId={clientId} />

	return 'grid' === layout ? <div className={`apbGridPosts columns-${desktop} columns-tablet-${tablet} columns-mobile-${mobile}`}>
		<PostsLoop />
	</div> /* Grid Layout */ :

		'grid1' === layout ? <div className='apbGrid1Posts'>
			<PostsLoop />
		</div> /* Grid 1 Layout */ :

			'masonry' === layout ? <div className={`apbMasonryPosts cols-${desktop} cols-tablet-${tablet} cols-mobile-${mobile}`}>
				<PostsLoop />
			</div> /* Masonry Layout */ :

				'slider' === layout ? <div className='apbSliderPosts'>
					<div className='swiper-wrapper'>
						<PostsLoop />
					</div>

					{sliderIsPage && <div className='swiper-pagination'></div>}

					{sliderIsPrevNext && <>
						<div className='swiper-button-prev'></div><div className='swiper-button-next'></div>
					</>}
				</div> /* Slider Layout */ :

					'ticker' === layout && <div className='apbTickerPosts'>
						<div>
							<PostsLoop />
						</div>
					</div> /* Ticker Layout */
}
export default DisplayPosts;