const PostMetaAuthor = ({ post, attributes }) => {
	const { author } = post;
	const { isMetaAuthor, isMetaAuthorLink, metaAuthorIcon } = attributes;

	return isMetaAuthor ? <span>
		{metaAuthorIcon ?
			<img src={metaAuthorIcon} alt='Author' /> :
			<span className='dashicons dashicons-admin-users' />}

		{false === isMetaAuthorLink ?
			<span>{author.name}</span> :
			<a href={author.link} target='_blank' rel='noreferrer'>{author.name}</a>}
	</span> : null;
};
export default PostMetaAuthor;