const PostMetaComment = ({ post, attributes }) => {
	const { link, commentCount } = post;
	const { isMetaComment, metaCommentIcon } = attributes;

	return isMetaComment ? <span>
		{metaCommentIcon ?
			<img src={metaCommentIcon} alt='Author' /> :
			<span className='dashicons dashicons-admin-comments' />}

		<a href={`${link}/#comments`} target='_blank' rel='noreferrer'>{commentCount}</a>
	</span> : null;
};
export default PostMetaComment;