import { dateFormat } from './../utils/functions';

const PostMetaDate = ({ post, attributes }) => {
	const { date } = post;
	const { isMetaDate, metaDateFormat, metaDateIcon } = attributes;

	return isMetaDate ? <span>
		{metaDateIcon ?
			<img src={metaDateIcon} alt='Date' /> :
			<span className='dashicons dashicons-calendar-alt' />}

		<span>{dateFormat(metaDateFormat, new Date(date))}</span>
	</span> : null;
};
export default PostMetaDate;